/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 620px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABXsDcKSUr/8QAGhAAAwADAQAAAAAAAAAAAAAAAAECERITIf/aAAgBAQABBQLemKqOjMjE/P/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAECAQE/Aar/xAAZEAACAwEAAAAAAAAAAAAAAAAAEAERMkH/2gAIAQEABj8C1Rs6pX//xAAaEAADAQEBAQAAAAAAAAAAAAAAASERMWGh/9oACAEBAAE/IUrA9Qb3PoW3y6Qm3hik7T//2gAMAwEAAgADAAAAEOvv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxCJkWH/xAAYEQACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAgEBPxBxsT0f/8QAHhAAAgIBBQEAAAAAAAAAAAAAAREAITFBUWFxocH/2gAIAQEAAT8QCSoObmMTXLK9UJGIDZvkrTYFuAbwhPajAlNU/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"southpaw left handed pitching\"\n        title=\"southpaw left handed pitching\"\n        src=\"/static/adb9fd10a5fb56a713403b0e987426e4/e83c5/southpaw.jpg\"\n        srcset=\"/static/adb9fd10a5fb56a713403b0e987426e4/4d5fb/southpaw.jpg 166w,\n/static/adb9fd10a5fb56a713403b0e987426e4/558f0/southpaw.jpg 333w,\n/static/adb9fd10a5fb56a713403b0e987426e4/e83c5/southpaw.jpg 620w\"\n        sizes=\"(max-width: 620px) 100vw, 620px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Approximately 10% of the human population is determined to be left-handed and as a result they often get special names for their handedness."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "The term “southpaw” was first used in baseball to refer primarily to pitchers.")), "\n", React.createElement(_components.p, null, "Traditionally, when baseball diamonds were built they were arranged such that a batter would be facing east (to avoid any glare from an afternoon sun) when at bat."), "\n", React.createElement(_components.p, null, "When pitching, a right-hander’s arm would be facing the North side of the baseball diamond while a left-handed pitcher’s arm would be facing the South side of the diamond-hence he was called a “southpaw”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
